import React, { lazy, useContext, useState, useEffect } from "react";
import {
  Grid,
  useTheme,
  useMediaQuery,
  AppBar,
  IconButton,
  Toolbar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import { Route, Routes } from "react-router-dom";
import TopNavBar from "./components/common/TopNavBar";
import Sidebar from "./components/common/sidebar/Sidebar";
import Homepage from "./components/homepage/Homepage";
import LoginSuccess from "./components/LoginSuccess";
import ConfirmQuote from "./components/ConfirmQuote";
import QuoteForm from "./components/QuoteForm";
import Index from "./components/index/Index";
import UserSettings from "./components/UserSettings";
import Voyages from "./components/Voyages";
import RoleGuard from "./components/guards/RoleGuard";
import Dashboard from "./components/dashboard/Dashboard";
import AdminQuotes from "./components/admin/AdminQuotes";
import AdminUser from "./components/admin/AdminUser";
import AdminEmail from "./components/admin/AdminEmail";
import AdminCompanies from "./components/admin/AdminCompanies";
import { UserContext } from "./components/AuthGuard";
import logo from "./assets/logo.svg";
import Waterlevels from "./components/Waterlevels";
import SnowLevels from "./components/SnowLevels";
import Weather from "./components/weather/Weather";
import ToReadMore from "./components/ToReadMore";
import Map from "./components/map/Map";

/**
 * Lazy load components to improve performance and reduce initial bundle size
 * this components are not needed on the first load or are not used by all users
 */
const SignInScreen = lazy(() => import("./components/SignInScreen"));
const NotFoundPage = lazy(() => import("./components/NotFoundPage"));
const PrivacyPolicy = lazy(() => import("./components/PrivacyPolicy"));
const Team = lazy(() => import("./components/Team"));
const About = lazy(() => import("./components/About"));
const MarketNews = lazy(() => import("./components/MarketNews"));
const PublicMarketNews = lazy(() => import("./components/PublicMarketNews"));
const NewsPost = lazy(() => import("./components/NewsPost"));

const generalRoutes = [
  {
    path: "/",
    element: <Homepage />,
  },
  {
    path: "/login",
    element: <SignInScreen />,
  },
  {
    path: "/team",
    element: <Team />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/news",
    element: <PublicMarketNews />,
  },
  {
    path: "/market-news",
    element: <ToReadMore />,
  },
  {
    path: "/news-post/:id",
    element: <NewsPost />,
  },
  {
    path: "/verify/:token/:accept",
    element: <ConfirmQuote />,
  },
  {
    path: "/login-success",
    element: (
      <RoleGuard roles={["admin", "write", "read", "none"]}>
        <LoginSuccess />
      </RoleGuard>
    ),
  },
  {
    path: "/user",
    element: (
      <RoleGuard roles={["admin", "write", "read", "none"]}>
        <UserSettings isNotVerified={true} />
      </RoleGuard>
    ),
  },
  {
    path: "/*",
    element: <NotFoundPage />,
  },
];

const routes = [
  {
    path: "/index",
    element: (
      <RoleGuard roles={["admin", "write", "read"]}>
        <Index />
      </RoleGuard>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <RoleGuard roles={["admin", "write", "read"]}>
        <div>
          <Dashboard />
        </div>
      </RoleGuard>
    ),
  },
  {
    path: "/enter-quote",
    element: (
      <RoleGuard roles={["admin", "write"]}>
        <QuoteForm />{" "}
      </RoleGuard>
    ),
  },
  {
    path: "/voyages",
    element: (
      <RoleGuard roles={["admin", "write"]}>
        <Voyages />{" "}
      </RoleGuard>
    ),
  },
  {
    path: "/user",
    element: (
      <RoleGuard roles={["admin", "write", "read", "none"]}>
        <UserSettings />
      </RoleGuard>
    ),
  },
  {
    path: "/admin-quotes",
    element: (
      <RoleGuard roles={["admin"]}>
        <AdminQuotes />
      </RoleGuard>
    ),
  },
  {
    path: "/admin-users",
    element: (
      <RoleGuard roles={["admin"]}>
        <AdminUser />{" "}
      </RoleGuard>
    ),
  },
  {
    path: "/admin-companies",
    element: (
      <RoleGuard roles={["admin"]}>
        <AdminCompanies />{" "}
      </RoleGuard>
    ),
  },
  {
    path: "/ship-trackingasd",
    element: (
      <RoleGuard roles={["admin", "write", "read"]} restrictedView="viewMap">
        <Map />
      </RoleGuard>
    ),
  },
  {
    path: "/admin-email",
    element: (
      <RoleGuard roles={["admin"]}>
        <AdminEmail />{" "}
      </RoleGuard>
    ),
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/login",
    element: <SignInScreen />,
  },
  {
    path: "/login-success",
    element: (
      <RoleGuard roles={["admin", "write", "read", "none"]}>
        <LoginSuccess />
      </RoleGuard>
    ),
  },
  {
    path: "/market-news",
    element: (
      <RoleGuard roles={["admin", "write", "read"]}>
        <MarketNews />
      </RoleGuard>
    ),
  },
  {
    path: "/weather",
    element: (
      <RoleGuard roles={["admin", "write", "read"]}>
        <Weather />
      </RoleGuard>
    ),
  },
  {
    path: "/snow-levels",
    element: (
      <RoleGuard roles={["admin", "write", "read"]}>
        <SnowLevels />
      </RoleGuard>
    ),
  },
  {
    path: "/water-levels",
    element: (
      <RoleGuard roles={["admin", "write", "read"]}>
        <Waterlevels />
      </RoleGuard>
    ),
  },
  {
    path: "/verify/:token/:accept",
    element: <ConfirmQuote />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/*",
    element: <NotFoundPage />,
  },
];

const StyledRoot = styled(Grid)(({ open }) => ({
  display: "grid",
  maxHeight: `${window.innerHeight}px`,
  height: `${window.innerHeight}px`,
}));

const StyledMain = styled(Grid)(({ matches }) => ({
  padding: window.location.pathname.substring(1) === "ship-tracking" ? 0 : "25px",
  minHeight: `${window.innerHeight}px`,
  overflow: "scroll",
  marginTop: matches ? 0 : "50px",
}));

const RenderUnauthScreens = () => {
  return (
    <>
      <TopNavBar />
      <Routes>
        {generalRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
    </>
  );
};

const Main = () => {
  const [user] = useContext(UserContext);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(() =>
    !matches ? true : localStorage.getItem("sidebarOpen") === "true",
  );

  if (!user || !user.role || user.role === "none")
    return <RenderUnauthScreens />;

  const handleSidebarOpen = () => {
    const opened = !sidebarOpen;
    setSidebarOpen(opened);
    localStorage.setItem("sidebarOpen", opened);
  };

  const handleMobileOpen = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <StyledRoot
      open={sidebarOpen}
      gridTemplateColumns={
        matches ? (sidebarOpen ? "250px 1fr" : "95px 1fr") : "12fr"
      }
    >
      <AppBar
        sx={{
          background:
            "linear-gradient(100deg, rgb(0, 18, 85) 50%, rgb(115 221 201) 100%)",
          display: {
            xs: "block",
            md: "none",
          },
        }}
      >
        <Toolbar
          sx={{
            flexWrap: "nowrap",
            justifyContent: "space-between",
          }}
        >
          <img src={logo} alt="logo" width="150px" />
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{
              display: {
                xs: "block",
                md: "none",
              },
            }}
            onClick={handleMobileOpen}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Sidebar
        matches={matches}
        mobileOpen={mobileOpen}
        large={sidebarOpen}
        handleOpen={handleSidebarOpen}
        handleMobileOpen={handleMobileOpen}
      />
      <StyledMain matches={matches}>
        <Routes>
          {routes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Routes>
      </StyledMain>
    </StyledRoot>
  );
};

export default Main;

import React, { useState } from "react";
import {
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddIcon from "@mui/icons-material/Add";
import { MoreHoriz } from "@mui/icons-material";

const FleetMenu = ({
  expanded,
  setExpanded,
  fleet,
  handleUpsertModal,
  handleDeleteFleet,
  removeVesselFromFleet,
  mapRef,
  handleFleetShipSelect,
}) => {
  const [anchorEls, setAnchorEls] = useState({});

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleMenuOpen = (event, id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: event.currentTarget }));
  };

  const handleMenuClose = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEls((prev) => ({ ...prev, [id]: null }));
  };

  return (
    <Grid sx={{ p: 2, width: 300 }}>
      <Typography variant="h6" component="div" textAlign="center">
        My Fleet
      </Typography>
      <Button fullWidth onClick={() => handleUpsertModal(null)}>
        <AddIcon fontSize="small" />
        New
      </Button>
      {fleet.map((currentFleet) => (
        <Accordion
          key={currentFleet.id}
          expanded={expanded === `panel${currentFleet.id}`}
          onChange={handleAccordionChange(`panel${currentFleet.id}`)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${currentFleet.id}-content`}
            id={`panel${currentFleet.id}-header`}
            sx={{
              flexDirection: "row-reverse",
              height: 50,
              minHeight: 32,
              padding: 0,
              "& .MuiAccordionSummary-content": {
                alignItems: "center !important",
                justifyContent: "space-between !important",
              },
            }}
          >
            <Typography
              pl={2}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <svg
                width="25"
                height="26"
                viewBox="0 0 4 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  marginRight: 10,
                  height: 25,
                  transform: "rotate(90deg)",
                }}
              >
                <path d="M0 7L2 0L4 7L2 5.5L0 7Z" fill={currentFleet.color} />
              </svg>
              {currentFleet.name}
            </Typography>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleMenuOpen(e, currentFleet.id);
              }}
            >
              <MoreHoriz fontSize="small" />
            </IconButton>
            <Menu
              container={mapRef.current}
              anchorEl={anchorEls[currentFleet.id]}
              open={Boolean(anchorEls[currentFleet.id])}
              onClose={(e) => handleMenuClose(e, currentFleet.id)}
              sx={{ zIndex: 9999, left: 25 }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                onClick={(e) => {
                  handleMenuClose(e, currentFleet.id);
                  handleUpsertModal(currentFleet);
                }}
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  handleMenuClose(e, currentFleet.id);
                  handleDeleteFleet(currentFleet);
                }}
              >
                Delete
              </MenuItem>
            </Menu>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              paddingLeft: 3,
              paddingRight: 3,
            }}
          >
            {currentFleet.vessels.map((vessel) => (
              <Grid
                key={vessel.id}
                container
                sx={{
                  display: "grid",
                  gridTemplateColumns: "30px 1fr min-content",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => handleFleetShipSelect(vessel.eni, vessel.mmsi)}
              >
                <svg
                  width="15"
                  height="16"
                  viewBox="0 0 4 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    marginRight: 10,
                    height: 16,
                    transform: "rotate(90deg)",
                  }}
                >
                  <path d="M0 7L2 0L4 7L2 5.5L0 7Z" fill={currentFleet.color} />
                </svg>
                <Typography variant="subtitle2">{vessel.name}</Typography>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    removeVesselFromFleet(currentFleet, vessel);
                  }}
                >
                  <HighlightOffIcon fontSize="small" />
                </IconButton>
              </Grid>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Grid>
  );
};

export default FleetMenu;

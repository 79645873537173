import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { Grid, Backdrop, CircularProgress, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { usePost, useVesselGet } from "../../request";
import { withErrorBoundary } from "react-error-boundary";
import GeneralErrorPage from "../GeneralErrorPage";
import L from "leaflet";
import {
  MapContainer,
  TileLayer,
  LayersControl,
  ZoomControl,
  useMap,
  ScaleControl,
} from "react-leaflet";
import { useTheme } from "@mui/material/styles";
import "leaflet/dist/leaflet.css";
import "leaflet-rotatedmarker";
import Terminals from "./layers/Terminals";
import Bargins from "./layers/bargins/Bargins";
import "./style.css";
import MapSidebar from "./sidebar/MapSidebar";
import VesselAutocomplete from "./layers/VesselAutocomplete";
import Alerts from "./Alerts";
import AddToFleetDialog from "./dialogs/AddToFleetDialog";
import DeleteFleetDialog from "./dialogs/DeleteFleetDialog";
import FleetDialog from "./dialogs/FleetDialog";
import Counter from "./sidebar/Counter";
import VesselHistory from "./layers/VesselHistory";
import { use } from "i18next";

function SetCenter({ center, zoom, setZoom }) {
  const map = useMap();
  const [prevCenter, setPrevCenter] = useState(null);

  useEffect(() => {
    map.on("zoomend", () => {
      setZoom(map.getZoom());
    });
    if (center && zoom !== undefined && prevCenter !== center) {
      map.setView(center, zoom);
      setPrevCenter(center);
    } else if (zoom !== undefined) {
      map.setZoom(zoom);
    }
  }, [center, zoom, map]);
  return null;
}

const defaultFilters = {
  deadweight: { type: "range", value: [0, 14000] },
  length: { type: "range", value: [0, 200] },
  m3: { type: "range", value: [0, 14000] },
  // year_built: { type: "range", value: [1900, new Date().getFullYear()] },
  tanks: { type: "range", value: [0, 30] },
  draft: { type: "range", value: [0, 20] },
  heating: { type: "bool", value: "all" },
  product: {
    type: "exact",
    value: {
      ALL: true,
      CPP: true,
      DPP: true,
      VEGOIL: true,
      GAS: true,
      LNG: true,
      UNKNOWN: true,
    },
  },
  pump: {
    type: "exact",
    value: {
      ALL: true,
      UNKNOWN: true,
      "SCREW PUMP": true,
      "DEEP WELL PUMP": true,
    },
  },
  tankType: {
    type: "exact",
    value: {
      ALL: true,
      UNKNOWN: true,
      MILDSTEEL: true,
      COATED: true,
      STAINLESS: true,
    },
  },
};

const defaultFleet = {
  id: null,
  name: "",
  color: "#ff0000",
  vessels: [],
};

function Map() {
  const [vessels, setVessels] = useState([]);
  const [filteredVessels, setFilteredVessels] = useState([]);
  const [fleet, setFleet] = useState([]);
  const [selectedVessel, setSelectedVessel] = useState(null);
  const [selectedVesselCache, setSelectedVesselCache] = useState(null);
  const [loading, setLoading] = useState(false);
  const [forceLoading, setForceLoading] = useState(false);
  const [center, setCenter] = useState(null);
  const [zoom, setZoom] = useState(6.5);
  const [key, setKey] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState(defaultFilters);
  const [upsertModalOpen, setUpsertModalOpen] = useState(false);
  const [addToFleetModalOpen, setAddToFleetModalOpen] = useState(false);
  const [selectedVesselToAdd, setSelectedVesselToAdd] = useState(null);
  const [isFleetUpdate, setIsFleetUpdate] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [fleetToDelete, setFleetToDelete] = useState(null);
  const [fleetToUpsert, setFleetToUpsert] = useState(defaultFleet);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [selectedVesselHistory, setSelectedVesselHistory] = useState(null);
  const [onlyFleet, setOnlyFleet] = useState(false);
  const [selectedFleetsToShow, setSelectedFleetsToShow] = useState([]);
  const [mapStyle, setMapStyle] = useState(
    localStorage.getItem("mapStyle") || "normal",
  );
  const theme = useTheme();
  const mapRef = useRef(null);
  const { t } = useTranslation();
  const get = useVesselGet();
  const post = useVesselGet();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const addFleet = async () => {
    try {
      setAddToFleetModalOpen(false);
      const response = await post("add-fleet", fleetToUpsert);
      if (!response || response.error) {
        handleSnackbarOpen(
          response.error || "Something went wrong, please reload!",
          "error",
        );
        handleFleetClear();
        return;
      }
      setFleet((prev) =>
        [...prev, { ...fleetToUpsert, id: response.id }]
          .sort((a, b) => a.name.toLowerCase().localeCompare(b.name))
          .map((fleet) => {
            fleet.vessels.sort((a, b) =>
              a.name.toLowerCase().localeCompare(b.name),
            );
            return fleet;
          }),
      );
      handleFleetClear();
      handleSnackbarOpen("Fleet added", "success");
    } catch (error) {
      handleFleetClear();
      handleSnackbarOpen("Error adding fleet", "error");
    }
  };

  const updateFleet = async () => {
    try {
      setAddToFleetModalOpen(false);
      const res = await post("update-fleet", fleetToUpsert);
      if (!res || res.error) {
        handleSnackbarOpen(
          res.error || "Something went wrong, please reload!",
          "error",
        );
        handleFleetClear();
        return;
      }
      setFleet((prev) =>
        prev
          .map((fleet) =>
            fleet.id === fleetToUpsert.id ? fleetToUpsert : fleet,
          )
          .sort((a, b) => a.name.toLowerCase().localeCompare(b.name))
          .map((fleet) => {
            fleet.vessels.sort((a, b) =>
              a.name.toLowerCase().localeCompare(b.name),
            );
            return fleet;
          }),
      );
      handleFleetClear();
      handleSnackbarOpen("Fleet updated", "success");
    } catch (error) {
      handleFleetClear();
      handleSnackbarOpen("Error updating fleet", "error");
    }
  };

  const deleteFleet = async () => {
    try {
      const res = await post("delete-fleet", { id: fleetToDelete.id });
      if (!res || res.error) {
        handleSnackbarOpen(
          res.error || "Something went wrong, please reload!",
          "error",
        );
        handleFleetClear();
        return;
      }
      setFleet((prev) => prev.filter((fleet) => fleet.id !== fleetToDelete.id));
      handleFleetClear();
      handleSnackbarOpen("Fleet deleted", "success");
    } catch (error) {
      handleFleetClear();
      handleSnackbarOpen("Error deleting fleet", "error");
    }
  };

  const addVesselToFleet = async (selectedFleet) => {
    try {
      const res = await post("add-vessel-fleet", {
        id: selectedFleet.id,
        vessel: selectedVesselToAdd,
      });
      if (!res || res.error) {
        handleSnackbarOpen(
          res.error || "Something went wrong, please reload!",
          "error",
        );
        handleFleetClear();
        return;
      }
      setFleet((prev) =>
        prev
          .map((fleet) =>
            fleet.id === selectedFleet.id
              ? { ...fleet, vessels: [...fleet.vessels, selectedVesselToAdd] }
              : fleet,
          )
          .sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
          )
          .map((fleet) => {
            fleet.vessels.sort((a, b) =>
              a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
            );
            return fleet;
          }),
      );
      handleFleetClear();
      handleSnackbarOpen("Vessel added to fleet", "success");
    } catch (error) {
      handleFleetClear();
      handleSnackbarOpen("Error adding vessel to fleet", "error");
    }
  };

  const removeVesselFromFleet = async (selectedFleet, selectedVessel) => {
    try {
      const res = await post("delete-vessel-fleet", {
        id: selectedFleet.id,
        vessel: selectedVessel,
      });
      if (!res || res.error) {
        handleSnackbarOpen(
          res.error || "Something went wrong, please reload!",
          "error",
        );
        handleFleetClear();
        return;
      }
      setFleet((prev) =>
        prev.map((fleet) =>
          fleet.id === selectedFleet.id
            ? {
                ...fleet,
                vessels: fleet.vessels.filter(
                  (vessel) => vessel.mmsi !== selectedVessel.mmsi,
                ),
              }
            : fleet,
        ),
      );
      handleFleetClear();
      handleSnackbarOpen("Vessel removed from fleet", "success");
    } catch (error) {
      console.error("Error removing vessel from fleet:", error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleFleetClear = () => {
    setIsFleetUpdate(false);
    setFleetToUpsert(defaultFleet);
    setUpsertModalOpen(false);
    setDeleteDialogOpen(false);
    setAddToFleetModalOpen(false);
    setSelectedVesselHistory(null);
  };

  const handleTrackClear = () => {
    setSelectedVesselHistory(null);
    setSelectedVessel(selectedVesselCache);
    setCenter(null);
  };

  const handleDeleteFleet = (fleet) => {
    setFleetToDelete(fleet);
    setDeleteDialogOpen(true);
  };

  const handleFleetShipSelect = (eni, mmsi) => {
    const vessel = filteredVessels.find(
      (v) => (v.eni && v.eni == eni) || v.mmsi == mmsi,
    );
    if (!vessel)
      return handleSnackbarOpen("This ship is off radar right now", "error");
    setSelectedVessel(vessel);
    setCenter([vessel.lat, vessel.lon]);
    setZoom(12);
  };

  const handleUpsertModal = (selectedFleet) => {
    if (selectedFleet) {
      setFleetToUpsert(selectedFleet);
      setIsFleetUpdate(true);
    } else {
      setFleetToUpsert(defaultFleet);
      setIsFleetUpdate(false);
    }
    setUpsertModalOpen(true);
  };

  const handleAddToFleetModal = (vessel) => {
    setAddToFleetModalOpen(true);
    setSelectedVesselToAdd(vessel);
  };

  const handleTrack = (vessel) => {
    setSelectedVesselHistory(vessel);
    setSelectedVesselCache(selectedVessel);
  };

  const fetchFleet = async () => {
    try {
      const response = await get("fleet");
      response
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name))
        .map((fleet) => {
          fleet.vessels.sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name),
          );
          return fleet;
        });
      setFleet(response);
    } catch (error) {
      console.error("Error fetching fleet data:", error);
    }
  };

  useEffect(() => {
    setSelectedFleetsToShow(
      fleet.map((f) => {
        const existingFleet = selectedFleetsToShow.find((sf) => sf.id === f.id);
        return {
          id: f.id,
          name: f.name,
          color: f.color,
          show: existingFleet ? existingFleet.show : true,
        };
      }),
    );
  }, [fleet]);

  const handleFleetShow = (id, checked) => {
    setSelectedFleetsToShow((prev) =>
      prev.map((fleet) =>
        fleet.id === id ? { ...fleet, show: checked } : fleet,
      ),
    );
  };

  const fetchVessels = async () => {
    try {
      setForceLoading(true);
      const response = await get("vessels");
      setVessels(response);
    } catch (error) {
      console.error("Error fetching vessel data:", error);
    }
    setForceLoading(false);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    await fetchVessels();
    await fetchFleet();
    setLoading(false);
  });

  useEffect(() => {
    let intervalId;

    if (!selectedVesselHistory) {
      intervalId = setInterval(() => {
        fetchVessels();
      }, 100000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [selectedVesselHistory, fetchVessels]);

  const fleetColor = useMemo(() => {
    return fleet.reduce((acc, cf) => {
      cf.vessels.forEach((cv) => {
        acc[cv.mmsi] = cf.color;
      });
      return acc;
    }, {});
  }, [fleet]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const isDefaultFilter = (filter, key) =>
      JSON.stringify(filter) === JSON.stringify(defaultFilters[key]);

    const applyRangeFilter = (vessel, filter, key) => {
      if (isDefaultFilter(filter, key)) return true;
      // if (!vessel[key]) return true;
      return vessel[key] >= filter.value[0] && vessel[key] <= filter.value[1];
    };

    const applyExactFilter = (vessel, filter, key) => {
      if (isDefaultFilter(filter, key)) return true;
      if (filter.value["ALL"]) return true;
      if (!vessel[key] || vessel[key] === "") {
        return filter.value["UNKNOWN"];
      }
      return filter.value[vessel[key].toUpperCase()];
    };

    const applyBoolFilter = (vessel, filter, key) => {
      if (isDefaultFilter(filter, key)) return true;
      return filter.value === vessel[key];
    };

    const applyFilters = (vessel) => {
      if (onlyFleet) {
        const selectedFleets = selectedFleetsToShow
          .filter((fleet) => fleet.show)
          .map((fleet) => fleet.id);

        const selectedVesselsForFleet = fleet
          .filter((fleet) => {
            return selectedFleets.includes(fleet.id);
          })
          .map((fleet) => fleet.vessels)
          .flat()
          .map((vessel) => vessel.eni);

        if (
          selectedFleets.length !== selectedFleetsToShow.length &&
          !selectedVesselsForFleet.includes(vessel.eni)
        ) {
          return false;
        }
        if (!fleetColor[vessel["mmsi"]]) {
          return false;
        }
      }

      // Apply other filters
      return Object.keys(filters).every((key) => {
        const filter = filters[key];
        if (filter.type === "range") {
          return applyRangeFilter(vessel, filter, key);
        } else if (filter.type === "exact") {
          return applyExactFilter(vessel, filter, key);
        } else if (
          filter.type === "bool" &&
          filter.value !== null &&
          filter.value !== "all"
        ) {
          return applyBoolFilter(vessel, filter, key);
        }
        return true;
      });
    };

    const filtered = vessels.filter(applyFilters);
    setFilteredVessels(filtered);
  }, [filters, vessels, fleetColor, onlyFleet, selectedFleetsToShow]);

  const clearFilters = () => {
    setFilters(defaultFilters);
    setOnlyFleet(false);
    setSelectedFleetsToShow(
      selectedFleetsToShow.map((fleet) => ({ ...fleet, show: true })),
    );
  };

  const handleDataRefresh = useCallback(() => {
    fetchVessels();
  }, [fetchVessels]);

  const handleClear = () => {
    setSearchValue("");
    setSelectedVessel(null);
    setCenter([51.01986821800572, 7.941116146878102]);
    setZoom(6.5);
  };

  const toggleMapStyle = (st) => {
    setMapStyle((prevStyle) => st);
    localStorage.setItem("mapStyle", st);
    setKey((prevKey) => prevKey + 1);
  };

  const filterOptions = useCallback((options, { inputValue }) => {
    return options.filter((option) => {
      return (
        option.mmsi?.toString().includes(inputValue) ||
        option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        (option.eni && option.eni.toString().includes(inputValue))
      );
    });
  }, []);

  const handleFullscreenToggle = () => {
    if (!document.fullscreenElement) {
      if (mapRef.current.requestFullscreen) {
        mapRef.current.requestFullscreen();
      } else if (mapRef.current.mozRequestFullScreen) {
        mapRef.current.mozRequestFullScreen();
      } else if (mapRef.current.webkitRequestFullscreen) {
        mapRef.current.webkitRequestFullscreen();
      } else if (mapRef.current.msRequestFullscreen) {
        mapRef.current.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  return (
    <div ref={mapRef}>
      <Grid
        container
        sx={{
          width: "100%",
          heigth: "100vh",
          position: "relative",
          [theme.breakpoints.down("sm")]: {
            height: "calc(100vh - 56px)",
          },
        }}
      >
        {!selectedVesselHistory && (
          <VesselAutocomplete
            filteredVessels={filteredVessels}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            selectedVessel={selectedVessel}
            setSelectedVessel={setSelectedVessel}
            setCenter={setCenter}
            setZoom={setZoom}
            mapRef={mapRef}
            filterOptions={filterOptions}
          />
        )}
        {!selectedVesselHistory && (
          <MapSidebar
            handleClear={handleClear}
            handleDataRefresh={handleDataRefresh}
            loading={forceLoading}
            changeMapStyle={toggleMapStyle}
            mapStyle={mapStyle}
            filters={filters}
            setFilters={setFilters}
            clearFilters={clearFilters}
            defaultFilters={defaultFilters}
            handleFullscreenToggle={handleFullscreenToggle}
            mapRef={mapRef}
            fleet={fleet}
            handleUpsertModal={handleUpsertModal}
            handleDeleteFleet={handleDeleteFleet}
            removeVesselFromFleet={removeVesselFromFleet}
            showOnlyFleet={setOnlyFleet}
            onlyFleet={onlyFleet}
            handleFleetShipSelect={handleFleetShipSelect}
            selectedFleetsToShow={selectedFleetsToShow}
            handleFleetShow={handleFleetShow}
          />
        )}
        {!selectedVesselHistory && <Counter vessels={filteredVessels} />}
        <MapContainer
          center={[51.01986821800572, 7.941116146878102]}
          preferCanvas={true}
          zoom={zoom}
          style={{
            width: "100%",
            height: "100vh",
          }}
          fadeAnimation={true}
          zoomControl={false}
          renderer={L.canvas()}
          updateWhenZooming={false}
          updateWhenIdle={true}
          maxZoom={17}
          minZoom={6}
          maxBounds={[
            [57.0, 2.0],
            [45.4642, 15.0],
          ]}
          useFlyTo={true}
        >
          <TileLayer
            url={
              mapStyle === "satelite"
                ? `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
                : "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            }
            className={`ship-${mapStyle}`}
            key={key}
          />
          {isSmallScreen && <ZoomControl position="bottomright" />}
          <LayersControl position="topleft" style={{ opacity: 0 }}>
            <Terminals />
            <Bargins
              vessels={filteredVessels}
              selectedVessel={selectedVessel}
              selectVessel={setSelectedVessel}
              handleAddToFleetModal={handleAddToFleetModal}
              fleetColor={fleetColor}
              handleTrack={handleTrack}
              hidden={selectedVesselHistory ? true : false}
            />
          </LayersControl>
          {selectedVesselHistory && (
            <VesselHistory
              selectedVessel={selectedVesselHistory}
              clear={handleTrackClear}
              setLoading={setLoading}
            />
          )}
          {isSmallScreen && <ScaleControl position="bottomleft" />}
          <SetCenter center={center} zoom={zoom} setZoom={setZoom} />
        </MapContainer>
        <FleetDialog
          open={upsertModalOpen}
          onClose={handleFleetClear}
          isFleetUpdate={isFleetUpdate}
          fleetToUpsert={fleetToUpsert}
          setFleetToUpsert={setFleetToUpsert}
          addFleet={addFleet}
          updateFleet={updateFleet}
          mapRef={mapRef}
        />
        <DeleteFleetDialog
          open={deleteDialogOpen}
          onClose={handleFleetClear}
          fleetToDelete={fleetToDelete}
          deleteFleet={deleteFleet}
          mapRef={mapRef}
        />
        <AddToFleetDialog
          open={addToFleetModalOpen}
          onClose={handleFleetClear}
          fleet={fleet}
          addVesselToFleet={addVesselToFleet}
          mapRef={mapRef}
        />
        <Alerts
          open={snackbarOpen}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          severity={snackbarSeverity}
          mapRef={mapRef}
        />
        <Backdrop
          sx={(theme) => ({
            color: "#fff",
            zIndex: theme.zIndex.drawer + 999999,
          })}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    </div>
  );
}

export default withErrorBoundary(Map, {
  fallback: <GeneralErrorPage />,
});
